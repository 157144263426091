@use 'sass:map';
@use '@angular/material/core/style/elevation' as elevation;

@mixin mat-card($config) {
  .mat-mdc-card {
    @include elevation.overridable-elevation(4);
    @at-root .mat-tab-header {
      // mat-tab-header class bestaat niet meer in ng16 kan weg???
      margin-left: 10px;
      margin-right: 10px;
    }
    .mat-mdc-card-header {
      margin-bottom: 16px;
    }
    .mat-mdc-tab-body-content & {
      &:first-of-type {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 11px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .mat-mdc-card {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        // TODO get 4px from mat-focus-indicator config
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
    .mat-mdc-card-actions {
      // remove negative margin for align with text in card
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 16px;

      button,
      a.mdc-button {
        margin: 0 8px;
      }
    }
    & > .mat-mdc-card-actions:last-child {
      margin-bottom: 0 !important;
    }
  }
}
