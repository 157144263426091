$mainColors: primary, accent;

@mixin is-legible() {
  @each $color in $mainColors {
    body.is-not-legible-#{$color} {
      // When the text is not readable due to theme colors, use contrast color instead.
      &.is-not-legible-#{$color}-use-contrast {
        .mdc-button.mat-#{$color} {
          --mdc-outlined-button-label-text-color: var(--theme-#{$color}-color-contrast-500);
        }

        .mat-mdc-icon-button.mat-#{$color} {
          --mdc-icon-button-icon-color: var(--theme-#{$color}-color-contrast-500);
        }

        .mat-tree-node {
          &.mat-tree-node-selected .node-name {
            color: var(--theme-#{$color}-color-contrast-500);
          }
          &:not(.no-content) .node-name .mat-icon {
            color: var(--theme-#{$color}-color-contrast-500);
          }
        }
      }

      // When the contrast color is not readable either, fallback to black.
      &.is-not-legible-#{$color}-use-black {
        .mdc-button.mat-#{$color} {
          --mdc-outlined-button-label-text-color: var(--light-theme-foreground-palette-text);
        }

        .mat-mdc-icon-button.mat-#{$color} {
          --mdc-icon-button-icon-color: var(--light-theme-foreground-palette-text);
        }

        .mat-tree-node {
          &.mat-tree-node-selected .node-name {
            color: var(--light-theme-foreground-palette-text);
          }
          &:not(.no-content) .node-name .mat-icon {
            color: var(--light-theme-foreground-palette-text);
          }
        }
      }
    }
  }
}
