@use 'sass:map';

@mixin mat-sidenav($grey) {
  mat-sidenav.scrollbar-thin .mat-drawer-inner-container {
    &::-webkit-scrollbar {
      width: 10px;
      background-color: map-get($grey, 200);
    }

    &::-webkit-scrollbar-thumb {
      background-color: map-get($grey, 400);
      border-radius: 6px;
      border: 3px solid map-get($grey, 200);
    }
  }
}
