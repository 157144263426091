//used in survey and others
$class-line-clamp: line-clamp !default;
@for $i from 1 through 6 {
  .#{$class-line-clamp}-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
  }
}
