// used in survey
@mixin mat-radio-group() {
  .mdc-label {
    white-space: normal !important;
    p {
      margin-bottom: 0;
    }
  }

  .mat-mdc-radio-group {
    position: relative;
    .mdc-label {
      white-space: normal !important;
      p {
        margin-bottom: 0;
      }
    }
  }
}
