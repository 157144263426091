// Include application wide helper classes
@use '../utilities/mixins/index' as mixins;
@include mixins.quill-editor-text-overrides();
@include mixins.quill-font-style-to-material();
@include mixins.quill-text-align();

@import 'material-icons/iconfont/material-icons.css';
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght,FILL@400,0..1';
@import '../utilities/utilities';

@import 'setup/bootstrap';
@import 'setup/material-design';
@import 'setup/basic';
