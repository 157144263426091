// used in survey and others
@mixin define-color-classes($palette, $type) {
  @each $name, $swatch in $palette {
    @if ($name == contrast) {
      @include define-contrast-color-classes(map-get($palette, $name), $type);
    } @else if type-of($swatch) == string or type-of($swatch) == color {
      .color--#{$type}-#{$name} {
        color: $swatch !important;
      }

      .bg--#{$type}-#{$name} {
        background-color: $swatch !important;
      }

      .border--#{$type}-#{$name} {
        border-color: $swatch !important;
      }
    }
  }
}

// used in survey and others
@mixin define-contrast-color-classes($palette, $type) {
  @each $name, $swatch in $palette {
    @if type-of($swatch) == string or type-of($swatch) == color {
      .color--#{$type}-contrast-#{$name} {
        color: $swatch !important;
      }
    }
  }
}
