@use 'sass:map';
html,
body {
  @extend .h-100;
  @extend .m-0;
  @extend .p-0;
  background-color: var(--theme-background-light-color);
}

.border-box {
  box-sizing: border-box;
}

.content-box {
  box-sizing: content-box;
}
