//user in survey
@mixin mat-checkbox() {
  .mdc-label {
    white-space: normal !important;
    p {
      margin-bottom: 0;
    }
  }
  .checkbox-group {
    position: relative;
    @extend .mat-form-field-appearance-fill !optional;
    .mdc-label {
      white-space: normal !important;
    }
  }
}
