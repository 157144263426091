@use 'sass:map';
// used in survey and others
@mixin define-vars($keyValue, $prefix: '', $root: true) {
  @each $key, $value in $keyValue {
    @if type-of($value) == string or type-of($value) == color {
      @if ($root) {
        :root {
          --#{$prefix}#{$key}: #{$value};
        }
      } @else {
        --#{$prefix}#{$key}: #{$value};
      }
    }
  }
}
