@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/core/theming/theming';
@use '../../utilities/material/index' as custom-mat;
@use '../../utilities/mixins/index' as mixins;
@use '../../utilities/material' as shared-mat;
@import '../../utilities/modifiers/define-vars';
@import '../../utilities/modifiers/define-color-classes';

@import 'material-design/tree';
@import 'material-design/snackbar';
@import 'material-design/card';
@import 'material-design/icon';
@import 'material-design/form-field';
@import 'material-design/dialog';
@import 'material-design/sidenav';

@include mat.core();
@include mat.strong-focus-indicators();
@include mat.core();

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: map-get(shared-mat.define-palette(), theme-primary),
      accent: map-get(shared-mat.define-palette(), theme-accent),
      warn: map-get(shared-mat.define-palette(), theme-warn),
    ),
    typography: shared-mat.$typography,
    density: 0,
    link-color: #3333ff,
  )
);

@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);
@include define-color-classes(map-get(shared-mat.define-palette(), theme-primary), 'primary');
@include define-color-classes(map-get(shared-mat.define-palette(), theme-accent), 'accent');
@include define-color-classes(map-get(shared-mat.define-palette(), theme-warn), 'warn');
@include define-color-classes(map-get(shared-mat.define-palette(), theme-grey), 'grey');

$light-theme-background-palette: map.set(mat.$m2-light-theme-background-palette, 'background', var(--theme-background-light-color));

@include define-vars($light-theme-background-palette, 'light-theme-background-palette-');
@include define-vars(mat.$m2-light-theme-foreground-palette, 'light-theme-foreground-palette-');
@include define-vars(map-get(shared-mat.define-palette(), theme-grey), 'theme-grey-color-');

@include mat-form-field($theme, shared-mat.$typography);
@include mat-card($theme);
@include mat-snackbar($theme);
@include mat-tree(map-get(shared-mat.define-palette(), theme-grey));
@include mat-icon();
@include mat-sidenav(map-get(shared-mat.define-palette(), theme-grey));
@include mat-dialog();
@include custom-mat.mat-checkbox();
@include custom-mat.mat-radio-group();
@include custom-mat.mat-autocomplete-correct-font-size();
@include mixins.is-legible();
@include mixins.quill-mat-button($theme);
@include mixins.focus-indicator($theme);

.mat-typography p {
  margin-bottom: 0;
}

:root {
  --dark-link-color: #33bbff;
  --light-link-color: #3333ff;

  /* Default */
  // --mdc-shape-small;

  /* Autocomplete */
  // --mat-autocomplete-background-color;

  /* Badge */
  // --mat-badge-text-font;
  // --mat-badge-text-size;
  // --mat-badge-text-color;
  // --mat-badge-text-weight;
  // --mat-badge-small-size-text-size;
  // --mat-badge-large-size-text-size;
  // --mat-badge-background-color;
  // --mat-badge-disabled-state-background-color;
  // --mat-badge-disabled-state-text-color;

  /* Button */
  .mdc-button {
    // --mdc-protected-button-container-color;
    // --mdc-protected-button-container-elevation;
    // --mdc-protected-button-label-text-color;
    // --mdc-protected-button-disabled-container-color;
    // --mdc-protected-button-disabled-label-text-color;
    // --mdc-protected-button-disabled-container-elevation
    // --mat-standard-button-toggle-height;
    // --mat-standard-button-toggle-text-font;
    // --mat-standard-button-toggle-text-color;
    // --mat-standard-button-toggle-background-color;
    // --mat-standard-button-toggle-divider-color;
    // --mat-standard-button-toggle-state-layer-color;
    // --mat-standard-button-toggle-selected-state-background-color;
    // --mat-standard-button-toggle-selected-state-text-color;
    // --mat-standard-button-toggle-disabled-state-text-color;
    // --mat-standard-button-toggle-disabled-state-background-color;
    // --mat-standard-button-toggle-disabled-selected-state-text-color;
    // --mat-standard-button-toggle-disabled-selected-state-background-color;
    // --mdc-text-button-container-shape;
    // --mdc-text-button-label-text-color
    // --mdc-icon-button-icon-size;
    // --mdc-icon-button-icon-color;
    // --mdc-icon-button-state-layer-size;
    // --mdc-icon-button-disabled-icon-color;
    // --mdc-icon-button-disabled-icon-opacity;
    // --mat-mdc-button-persistent-ripple-color;
    // --mat-mdc-button-ripple-color;

    &.mat-primary {
      --mdc-protected-button-label-text-color: var(--theme-primary-color-contrast-500);
    }
    &.mat-accent {
      --mdc-protected-button-label-text-color: var(--theme-accent-color-contrast-500);
    }
    &.mat-warn {
      --mdc-protected-button-label-text-color: var(--theme-warn-color-contrast-500);
    }
  }
  a.mdc-button {
    .mdc-button__label {
      line-height: 2rem;
    }
  }

  /* Bottom sheet */
  // --mat-bottom-sheet-container-text-font;
  // --mat-bottom-sheet-container-text-size;
  // --mat-bottom-sheet-container-text-color;
  // --mat-bottom-sheet-container-text-weight;
  // --mat-bottom-sheet-container-text-tracking;
  // --mat-bottom-sheet-container-text-line-height;
  // --mat-bottom-sheet-container-background-color;

  /* Checkbox */
  .mat-mdc-checkbox {
    // --mdc-checkbox-state-layer-size;
    // --mdc-checkbox-unselected-icon-color;
    // --mdc-checkbox-unselected-hover-icon-color;
    // --mdc-checkbox-unselected-hover-state-layer-color;
    // --mdc-checkbox-unselected-hover-state-layer-opacity;
    // --mdc-checkbox-unselected-focus-icon-color;
    // --mdc-checkbox-unselected-focus-state-layer-color;
    // --mdc-checkbox-unselected-focus-state-layer-opacity;
    // --mdc-checkbox-unselected-pressed-icon-color;
    // --mdc-checkbox-unselected-pressed-state-layer-color;
    // --mdc-checkbox-unselected-pressed-state-layer-opacity;
    --mdc-checkbox-selected-checkmark-color: var(--theme-primary-color-contrast-500);
    // --mdc-checkbox-selected-icon-color;
    // --mdc-checkbox-selected-hover-icon-color;
    // --mdc-checkbox-selected-hover-state-layer-color;
    // --mdc-checkbox-selected-hover-state-layer-opacity;
    // --mdc-checkbox-selected-focus-state-layer-opacity;
    // --mdc-checkbox-selected-focus-icon-color;
    // --mdc-checkbox-selected-focus-state-layer-color;
    // --mdc-checkbox-selected-pressed-icon-color;
    // --mdc-checkbox-selected-pressed-state-layer-color;
    // --mdc-checkbox-selected-pressed-state-layer-opacity;
    // --mdc-checkbox-disabled-selected-icon-color;
    // --mdc-checkbox-disabled-selected-checkmark-color;
    // --mdc-checkbox-disabled-unselected-icon-color;

    &.mat-primary {
      --mdc-checkbox-selected-checkmark-color: var(--theme-primary-color-contrast-500);
    }
    &.mat-accent {
      --mdc-checkbox-selected-checkmark-color: var(--theme-accent-color-contrast-500);
    }
    &.mat-warning {
      --mdc-checkbox-selected-checkmark-color: var(--theme-warning-color-contrast-500);
    }
  }

  /* Chip */
  .mdc-evolution-chip-set__chips {
    // fix ellipsis see https://stackoverflow.com/questions/76313304/angular-material-16-chip-text-with-overflow
    max-width: 100% !important;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    // fix ellipsis see https://stackoverflow.com/questions/76313304/angular-material-16-chip-text-with-overflow
    overflow: hidden !important;
  }

  /* Datepicker */
  // --mat-datepicker-toggle-icon-color;
  // --mat-datepicker-toggle-active-state-icon-color;
  // --mat-datepicker-calendar-container-background-color;
  // --mat-datepicker-calendar-container-text-color;
  // --mat-datepicker-calendar-text-font;
  // --mat-datepicker-calendar-text-size;
  // --mat-datepicker-calendar-header-text-size;
  // --mat-datepicker-calendar-header-text-weight;
  // --mat-datepicker-calendar-header-divider-color;
  // --mat-datepicker-calendar-header-text-color;
  // --mat-datepicker-calendar-body-label-text-size;
  // --mat-datepicker-calendar-body-label-text-color;
  // --mat-datepicker-calendar-body-label-text-weight;
  // --mat-datepicker-calendar-period-button-text-size;
  // --mat-datepicker-calendar-period-button-icon-color;
  // --mat-datepicker-calendar-period-button-text-weight;
  // --mat-datepicker-calendar-date-selected-state-text-color;
  // --mat-datepicker-calendar-date-selected-state-background-color;
  // --mat-datepicker-calendar-date-selected-disabled-state-background-color;
  // --mat-datepicker-calendar-date-today-outline-color;
  // --mat-datepicker-calendar-date-today-selected-state-outline-color;
  // --mat-datepicker-calendar-date-today-disabled-state-outline-color;
  // --mat-datepicker-calendar-date-focus-state-background-color;
  // --mat-datepicker-calendar-date-hover-state-background-color;
  // --mat-datepicker-calendar-date-in-range-state-background-color;
  // --mat-datepicker-calendar-date-in-comparison-range-state-background-color;
  // --mat-datepicker-calendar-date-in-overlap-range-state-background-color;
  // --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color;
  // --mat-datepicker-calendar-navigation-button-icon-color;
  // --mat-datepicker-calendar-date-text-color;
  // --mat-datepicker-calendar-date-outline-color;
  // --mat-datepicker-calendar-date-disabled-state-text-color;
  // --mat-datepicker-calendar-date-preview-state-outline-color;
  // --mat-datepicker-range-input-separator-color;
  // --mat-datepicker-range-input-disabled-state-separator-color;
  // --mat-datepicker-range-input-disabled-state-text-color;

  /* Dialog */
  .mat-mdc-dialog-container {
    // --mdc-dialog-container-color;
    // --mdc-dialog-container-shape;
    // --mdc-dialog-container-elevation;
    // --mdc-dialog-container-elevation-shadow;
    // --mdc-dialog-container-shadow-color;
    // --mdc-dialog-subhead-font;
    // --mdc-dialog-subhead-size;
    --mdc-dialog-subhead-color: rgb(0, 57, 89);
    // --mdc-dialog-subhead-weight;
    // --mdc-dialog-subhead-tracking;
    // --mdc-dialog-subhead-line-height;
    // --mdc-dialog-supporting-text-font;
    // --mdc-dialog-supporting-text-size;
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
    // --mdc-dialog-supporting-text-weight;
    // --mdc-dialog-supporting-text-tracking;
    // --mdc-dialog-supporting-text-line-height;
    // --mat-dialog-transition-duration;
  }

  /* Divider */
  // --mat-divider-color;

  /* Expansion panel */
  // --mat-expansion-header-text-font;
  // --mat-expansion-header-text-size;
  // --mat-expansion-header-text-color;
  // --mat-expansion-header-text-weight;
  // --mat-expansion-header-text-tracking;
  // --mat-expansion-header-text-line-height;
  // --mat-expansion-header-collapsed-state-height;
  // --mat-expansion-header-expanded-state-height;
  // --mat-expansion-header-hover-state-layer-color;
  // --mat-expansion-header-focus-state-layer-color;
  // --mat-expansion-header-disabled-state-text-color;
  // --mat-expansion-header-description-color;
  // --mat-expansion-header-indicator-color;
  // --mat-expansion-container-text-font;
  // --mat-expansion-container-text-size;
  // --mat-expansion-container-text-color;
  // --mat-expansion-container-text-weight;
  // --mat-expansion-container-text-tracking;
  // --mat-expansion-container-text-line-height;
  // --mat-expansion-container-background-color;
  // --mat-expansion-actions-divider-color;

  /* Fab */
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    // --mdc-fab-container-shape;
    --mdc-fab-container-color: var(--theme-primary-color-500);
    --mat-fab-foreground-color: var(--theme-primary-color-contrast-500);
    --mat-fab-state-layer-color: var(--theme-primary-color-contrast-500);
    --mat-mdc-fab-color: var(--theme-primary-color-contrast-500);
    --mdc-fab-icon-color: var(--theme-primary-color-contrast-500);

    &.mat-primary {
      --mdc-fab-small-container-color: var(--theme-primary-color-500);
      --mdc-fab-container-color: var(--theme-primary-color-500);
      --mat-fab-small-foreground-color: var(--theme-primary-color-contrast-500);
      --mat-fab-foreground-color: var(--theme-primary-color-contrast-500);
      --mat-fab-state-layer-color: var(--theme-primary-color-contrast-500);
      --mat-fab-small-state-layer-color: var(--theme-primary-color-contrast-500);
      --mat-mdc-fab-color: var(--theme-primary-color-contrast-500);
      --mdc-fab-icon-color: var(--theme-primary-color-contrast-500);
    }

    &.mat-accent {
      --mdc-fab-container-color: var(--theme-accent-color-500);
      --mdc-fab-small-container-color: var(--theme-accent-color-500);
      --mat-fab-foreground-color: var(--theme-accent-color-contrast-500);
      --mat-fab-small-foreground-color: var(--theme-accent-color-contrast-500);
      --mat-fab-state-layer-color: var(--theme-accent-color-contrast-500);
      --mat-fab-small-state-layer-color: var(--theme-accent-color-contrast-500);
      --mat-mdc-fab-color: var(--theme-accent-color-contrast-500);
      --mdc-fab-icon-color: var(--theme-accent-color-contrast-500);
    }

    &.mat-warning {
      --mdc-fab-container-color: var(--theme-warning-color-500);
      --mdc-fab-small-container-color: var(--theme-warning-color-500);
      --mat-fab-foreground-color: var(--theme-warning-color-contrast-500);
      --mat-fab-small-foreground-color: var(--theme-warning-color-contrast-500);
      --mat-fab-state-layer-color: var(--theme-warning-color-contrast-500);
      --mat-fab-small-state-layer-color: var(--theme-warning-color-contrast-500);
      --mat-mdc-fab-color: var(--theme-warning-color-contrast-500);
      --mdc-fab-icon-color: var(--theme-warning-color-contrast-500);
    }
  }

  /* Form field */
  .mat-mdc-form-field {
    // --mat-mdc-form-field-label-offset-x;
    // --mdc-filled-text-field-label-text-font;
    // --mdc-filled-text-field-label-text-size;
    // --mdc-filled-text-field-label-text-weight;
    // --mdc-filled-text-field-label-text-tracking;
    // --mdc-outlined-text-field-label-text-font;
    // --mdc-outlined-text-field-label-text-size;
    // --mdc-outlined-text-field-label-text-weight;
    // --mdc-outlined-text-field-label-text-tracking;
    // --mat-form-field-container-text-font;
    // --mat-form-field-container-text-size;
    // --mat-form-field-container-text-weight;
    // --mat-form-field-container-text-tracking;
    // --mat-form-field-container-text-line-height;
    // --mat-form-field-outlined-label-text-populated-size;
    // --mat-form-field-subscript-text-font;
    // --mat-form-field-subscript-text-size;
    // --mat-form-field-subscript-text-weight;
    // --mat-form-field-subscript-text-tracking;
    // --mat-form-field-subscript-text-line-height;
    // --mat-form-field-disabled-input-text-placeholder-color;
    // --mdc-filled-text-field-caret-color;
    // --mdc-filled-text-field-container-color;
    // --mdc-filled-text-field-label-text-color;
    // --mdc-filled-text-field-input-text-color;
    // --mdc-filled-text-field-input-text-placeholder-color;
    // --mdc-filled-text-field-active-indicator-color;
    // --mdc-filled-text-field-hover-active-indicator-color;
    // --mdc-filled-text-field-focus-label-text-color;
    // --mdc-filled-text-field-focus-active-indicator-color;
    // --mdc-filled-text-field-disabled-container-color;
    // --mdc-filled-text-field-disabled-label-text-color;
    // --mdc-filled-text-field-disabled-input-text-color;
    // --mdc-filled-text-field-disabled-active-indicator-color;
    // --mdc-filled-text-field-error-label-text-color;
    // --mdc-filled-text-field-error-caret-color;
    // --mdc-filled-text-field-error-focus-label-text-color;
    // --mdc-filled-text-field-error-active-indicator-color;
    // --mdc-filled-text-field-error-focus-active-indicator-color;
    // --mdc-filled-text-field-error-hover-active-indicator-color;
    // --mdc-outlined-text-field-caret-color;
    // --mdc-outlined-text-field-label-text-color;
    // --mdc-outlined-text-field-input-text-color;
    // --mdc-outlined-text-field-input-text-placeholder-color;
    // --mdc-outlined-text-field-outline-color;
    // --mdc-outlined-text-field-hover-outline-color;
    // --mdc-outlined-text-field-focus-outline-color;
    // --mdc-outlined-text-field-focus-label-text-color;
    // --mdc-outlined-text-field-disabled-label-text-color;
    // --mdc-outlined-text-field-disabled-input-text-color;
    // --mdc-outlined-text-field-disabled-outline-color;
    // --mdc-outlined-text-field-error-caret-color;
    // --mdc-outlined-text-field-error-focus-label-text-color;
    // --mdc-outlined-text-field-error-label-text-color;
    // --mdc-outlined-text-field-error-focus-outline-color;
    // --mdc-outlined-text-field-error-hover-outline-color;
    // --mdc-outlined-text-field-error-outline-color;
    // --mat-form-field-subscript-text-font;
    // --mat-form-field-subscript-text-line-height;
    // --mat-form-field-subscript-text-size;
    // --mat-form-field-subscript-text-tracking;
    // --mat-form-field-subscript-text-weight;

    z-index: 1;

    .mat-mdc-form-field-icon-suffix {
      padding-right: var(--spacer1) !important;
    }
  }

  /* Grid list */
  // --mat-grid-list-tile-header-primary-text-size;
  // --mat-grid-list-tile-header-secondary-text-size;
  // --mat-grid-list-tile-footer-primary-text-size;
  // --mat-grid-list-tile-footer-secondary-text-size;

  /* Icon */
  // --mat-icon-color;

  // List
  .mat-mdc-list-item {
    // --mdc-list-list-item-label-text-font;
    // --mdc-list-list-item-label-text-size;
    // --mdc-list-list-item-label-text-weight;
    // --mdc-list-list-item-label-text-line-height;
    // --mdc-list-list-item-label-text-tracking;
    --mdc-list-list-item-selected-container-color: rgba(0, 0, 0, 0.12);

    &.mdc-list-item--selected,
    &.mdc-list-item--activated {
      .mdc-list-item__primary-text {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  /* Menu */
  // --mat-menu-container-color;
  // --mat-menu-item-icon-color;
  // --mat-menu-item-label-text-font;
  // --mat-menu-item-label-text-size;
  // --mat-menu-item-label-text-color;
  // --mat-menu-item-label-text-weight;
  // --mat-menu-item-label-text-tracking;
  // --mat-menu-item-label-text-line-height;
  // --mat-menu-item-hover-state-layer-color;
  // --mat-menu-item-focus-state-layer-color;

  /* Option */
  // --mat-option-label-text-font;
  // --mat-option-label-text-size;
  // --mat-option-label-text-color;
  // --mat-option-label-text-weight;
  // --mat-option-label-text-tracking;
  // --mat-option-label-text-line-height;
  // --mat-option-hover-state-layer-color;
  // --mat-option-focus-state-layer-color;
  // --mat-option-selected-state-layer-color;
  // --mat-option-selected-state-label-text-color;
  // --mat-optgroup-label-text-font;
  // --mat-optgroup-label-text-size;
  // --mat-optgroup-label-text-color;
  // --mat-optgroup-label-text-weight;
  // --mat-optgroup-label-text-tracking;
  // --mat-optgroup-label-text-line-height;

  /* Paginator */
  // --mat-paginator-container-size;
  // --mat-paginator-container-text-font;
  // --mat-paginator-container-text-size;
  // --mat-paginator-container-text-color;
  // --mat-paginator-container-text-weight;
  // --mat-paginator-container-text-tracking;
  // --mat-paginator-container-text-line-height;
  // --mat-paginator-container-background-color;
  // --mat-paginator-select-trigger-text-size;
  // --mat-paginator-enabled-icon-color;
  // --mat-paginator-disabled-icon-color;

  /* Progress bar */
  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: var(--theme-primary-color-100);
    // --mdc-linear-progress-track-height;
    // --mdc-linear-progress-track-shape;
    --mdc-linear-progress-active-indicator-color: var(--theme-primary-color-800);
    // --mdc-linear-progress-active-indicator-height;
    // --mdc-linear-progress-primary-half;
    // --mdc-linear-progress-primary-full;
    // --mdc-linear-progress-primary-half-neg;
    // --mdc-linear-progress-primary-full-neg;
    // --mdc-linear-progress-secondary-quarter;
    // --mdc-linear-progress-secondary-half;
    // --mdc-linear-progress-secondary-full;
    // --mdc-linear-progress-secondary-quarter-neg;
    // --mdc-linear-progress-secondary-half-neg;
    // --mdc-linear-progress-secondary-full-neg;

    &.mat-primary {
      --mdc-linear-progress-track-color: var(--theme-primary-color-100);
      --mdc-linear-progress-active-indicator-color: var(--theme-primary-color-800);
    }
    &.mat-accent {
      --mdc-linear-progress-track-color: var(--theme-accent-color-100);
      --mdc-linear-progress-active-indicator-color: var(--theme-accent-color-800);
    }
    &.mat-warning {
      --mdc-linear-progress-track-color: var(--theme-warning-color-100);
      --mdc-linear-progress-active-indicator-color: var(--theme-warning-color-800);
    }
  }

  /* Radio buttons */
  .mat-mdc-radio-group {
    // --mdc-radio-state-layer-size;
    // --mdc-radio-selected-focus-icon-color;
    // --mdc-radio-selected-hover-icon-color;
    // --mdc-radio-selected-icon-;
    // --mdc-radio-selected-pressed-icon-color;
    // --mdc-radio-unselected-hover-icon-color;
    // --mdc-radio-unselected-icon-color;
    // --mat-radio-disabled-label-color;
    // --mdc-radio-disabled-selected-icon-color;
    // --mdc-radio-disabled-selected-icon-opacity;
    // --mdc-radio-disabled-unselected-icon-color;
    // --mdc-radio-disabled-unselected-icon-opacity;
    // --mat-radio-ripple-color;
    // --mat-radio-checked-ripple-color;

    .mat-mdc-radio-button {
      margin-bottom: 0 !important;
    }
  }

  /* Select */
  // --mat-select-trigger-text-font;
  // --mat-select-trigger-text-size;
  // --mat-select-trigger-text-weight;
  // --mat-select-trigger-text-tracking;
  // --mat-select-trigger-text-line-height;
  // --mat-select-placeholder-text-color;
  // --mat-select-panel-background-color;
  // --mat-select-focused-arrow-color;
  // --mat-select-enabled-trigger-text-color;
  // --mat-select-enabled-arrow-color;
  // --mat-select-disabled-trigger-text-color;
  // --mat-select-disabled-arrow-color;
  // --mat-select-invalid-arrow-color;

  /* Sidenav */
  // --mat-sidenav-container-divider-color;
  // --mat-sidenav-container-background-color;
  // --mat-sidenav-container-text-color;
  // --mat-sidenav-content-background-color;
  // --mat-sidenav-content-text-color;
  // --mat-sidenav-scrim-color;

  /* Stepper */
  // --mat-stepper-line-color;
  // --mat-stepper-container-text-font;
  // --mat-stepper-container-color;
  // --mat-stepper-header-height;
  // --mat-stepper-header-label-text-font;
  // --mat-stepper-header-label-text-size;
  // --mat-stepper-header-label-text-color;
  // --mat-stepper-header-label-text-weight;
  // --mat-stepper-header-optional-label-text-color;
  // --mat-stepper-header-icon-foreground-color;
  // --mat-stepper-header-icon-background-color;
  // --mat-stepper-header-hover-state-layer-color;
  // --mat-stepper-header-focus-state-layer-color;
  // --mat-stepper-header-selected-state-label-text-size;
  // --mat-stepper-header-selected-state-label-text-color;
  // --mat-stepper-header-selected-state-label-text-weight: var(--theme-body1-font-weight);
  // --mat-stepper-header-selected-state-icon-background-color;
  // --mat-stepper-header-selected-state-icon-foreground-color;
  // --mat-stepper-header-done-state-icon-background-color;
  // --mat-stepper-header-done-state-icon-foreground-color;
  // --mat-stepper-header-edit-state-icon-background-color;
  // --mat-stepper-header-edit-state-icon-foreground-color;
  // --mat-stepper-header-error-state-label-text-size;
  // --mat-stepper-header-error-state-label-text-color;
  // --mat-stepper-header-error-state-icon-foreground-color;
  // --mat-stepper-header-error-state-icon-background-color;

  /* Tabs */
  .mat-mdc-tab-group {
    // --mat-tab-header-label-text-font;
    // --mat-tab-header-label-text-size;
    // --mat-tab-header-label-text-weight;
    // --mat-tab-header-label-text-tracking;
    // --mat-tab-header-label-text-line-height;
    --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.87);
    // --mat-tab-header-active-hover-label-text-color;
    // --mat-tab-header-active-hover-indicator-color;
    --mat-tab-header-active-focus-label-text-color: rgba(0, 0, 0, 0.87);
    // --mat-tab-header-active-focus-indicator-color;
    // --mat-tab-header-active-ripple-color;
    // --mat-tab-header-inactive-label-text-color;
    // --mat-tab-header-inactive-hover-label-text-color;
    // --mat-tab-header-inactive-focus-label-text-color;
    // --mat-tab-header-inactive-ripple-color;
    // --mat-tab-header-disabled-ripple-color;
    // --mat-tab-header-pagination-icon-color;
    // --mdc-tab-indicator-active-indicator-color;
    // --mdc-tab-indicator-active-indicator-height;
    // --mdc-tab-indicator-active-indicator-shape;
    // --mdc-secondary-navigation-tab-container-height;
    // --mat-tab-animation-duration';

    .mat-mdc-tab-header {
      @extend .px-1;
    }
  }

  /* Table */
  .mat-mdc-table {
    // --mat-table-background-color;
    // --mat-table-header-container-height;
    // --mat-table-header-headline-font;
    --mat-table-header-headline-size: 0.75rem;
    --mat-table-header-headline-color: rgba(0, 0, 0, 0.5);
    --mat-table-header-headline-weight: 600;
    // --mat-table-header-headline-tracking;
    --mat-table-header-headline-line-height: var(--theme-body1-line-height);
    // --mat-table-row-item-container-height;
    // --mat-table-row-item-label-text-font;
    // --mat-table-row-item-label-text-size;
    // --mat-table-row-item-label-text-color;
    // --mat-table-row-item-label-text-weight;
    // --mat-table-row-item-label-text-tracking;
    // --mat-table-row-item-label-text-line-height;
    // --mat-table-row-item-outline-color;
    // --mat-table-footer-container-height;
    // --mat-table-footer-supporting-text-font;
    // --mat-table-footer-supporting-text-size;
    // --mat-table-footer-supporting-text-weight;
    // --mat-table-footer-supporting-text-tracking;
    // --mat-table-footer-supporting-text-line-height;

    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-mdc-footer-cell {
      padding: var(--spacer0);

      &:first-of-type {
        padding-left: var(--spacer2);
      }
      &:last-of-type {
        padding-right: var(--spacer2);
      }
    }

    .mat-mdc-table-sticky {
      &.mat-mdc-header-cell {
        z-index: 10 !important;
      }
    }
  }

  /* Toolbar */
  // --mat-toolbar-title-text-font;
  // --mat-toolbar-title-text-size;
  // --mat-toolbar-title-text-weight;
  // --mat-toolbar-title-text-tracking;
  // --mat-toolbar-title-text-line-height;
  // --mat-toolbar-standard-height;
  // --mat-toolbar-mobile-height;
}
