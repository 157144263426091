@use 'sass:map';
@use '@angular/material' as mat;

@mixin quill-editor-text-overrides() {
  .ql-editor p,
  .ql-editor li {
    @extend .mat-body !optional;
  }

  .ql-editor * {
    white-space: initial !important;
  }
}

@mixin quill-text-align() {
  .ql-align {
    &-center {
      @extend .text-center;
    }

    &-right {
      @extend .text-end;
    }
  }
}

@mixin quill-list-overrides() {
  li[data-list='bullet'] {
    list-style-type: none;
  }

  li[data-list='bullet'] > .ql-ui:before {
    content: '\2022';
  }

  li > .ql-ui:before {
    display: inline-block;
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
    white-space: nowrap;
    width: 1.2em;
  }
}

@mixin _button-styles($color) {
  &:visited,
  &:active,
  &.mat-button {
    color: $color;
  }
}

// mixin to give the legacy mat-button the styles of the new button
@mixin quill-mat-button($theme-or-config) {
  $primary: map.get($theme-or-config, primary);
  $accent: map.get($theme-or-config, accent);
  $is-dark: map.get($theme-or-config, is-dark);
  $link-color: map.get($theme-or-config, link-color);
  $primary-color: mat.m2-get-color-from-palette($primary, 500);
  $primary-color-contrast: mat.m2-get-color-from-palette($primary, '500-contrast');
  $accent-color: mat.m2-get-color-from-palette($accent, 500);
  $accent-color-contrast: mat.m2-get-color-from-palette($accent, '500-contrast');

  @if ($is-dark == false) {
    a[data-layout] {
      &.mat-primary {
        @include _button-styles($primary-color);
      }

      &.mat-accent {
        @include _button-styles($accent-color);
      }
    }
  }

  // this is far from the best solution, but we cannot @extend mdc buttons since they are private.
  // for now this is the only way to make the legacy button look the same as the mdc button
  a[data-layout] {
    &.mat-raised-button,
    &.mat-stroked-button {
      background-color: var(--mdc-protected-button-container-color, transparent);
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      min-width: 64px;
      border: none;
      outline: none;
      user-select: none;
      -webkit-appearance: none;
      overflow: visible;
      vertical-align: middle;
      height: 36px;
      -webkit-font-smoothing: antialiased;
      font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--theme-button-font-family)));
      font-size: var(--mdc-typography-button-font-size, var(--theme-button-font-size));
      line-height: var(--mdc-typography-button-line-height, var(--theme-button-line-height));
      font-weight: var(--mdc-typography-button-font-weight, var(--theme-button-font-weight));
      letter-spacing: var(--mdc-typography-button-letter-spacing, var(--theme-button-letter-spacing));
      text-decoration: var(--mdc-typography-button-text-decoration, none);
      text-transform: var(--mdc-typography-button-text-transform, none);
    }

    &.mat-raised-button {
      @if ($is-dark == false) {
        &.mat-primary {
          background-color: $primary-color;
          color: $primary-color-contrast;
        }

        &.mat-accent {
          background-color: $accent-color;
          color: $accent-color-contrast;
        }
      } @else {
        background-color: var(--mat-app-text-color, inherit);
        color: var(--mat-app-background-color, transparent);
      }
      padding: 0 16px 0 16px;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: var(
        --mdc-protected-button-container-elevation,
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12)
      );
      border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &.mat-stroked-button {
      border-style: solid;
      border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
      border-color: var(--mdc-outlined-button-outline-color, rgba(0, 0, 0, 0.12));
      padding: 0 15px 0 15px;
      border-width: var(--mdc-outlined-button-outline-width, 1px);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
}

@mixin quill-font-style-to-material() {
  .ql-size {
    &-small,
    &-caption {
      @extend .mat-caption !optional;
    }

    &-normal,
    &-body {
      @extend .mat-body !optional;
    }

    &-large,
    &-subtitle {
      @extend .mat-subtitle-2 !optional;
    }

    &-huge,
    &-title {
      @extend .mat-subtitle-1 !optional;
    }
  }

  // italic, bold en underline
  em.ql-size {
    &-small,
    &-caption,
    &-normal,
    &-body,
    &-large,
    &-subtitle,
    &-huge,
    &-title {
      font-style: italic;
    }
  }

  strong.ql-size {
    &-small,
    &-caption,
    &-normal,
    &-body,
    &-large,
    &-subtitle,
    &-huge,
    &-title {
      font-weight: bold;
    }
  }

  u.ql-size {
    &-small,
    &-caption,
    &-normal,
    &-body,
    &-large,
    &-subtitle,
    &-huge,
    &-title {
      text-decoration: underline;
    }
  }

  lsu-mat-quill .ql-container .ql-editor {
    & h1 {
      @extend .mat-h1 !optional;
    }

    & h2 {
      @extend .mat-h2 !optional;
    }
  }

  lsu-mat-quill .ql-toolbar .ql-picker-label {
    padding: 0 !important;
    border: none !important;
  }
}
