@use 'sass:map';
@use '@angular/material' as mat;

$border-width: 2px;
$border-style: dotted;
$border-color-dark: map-get(mat.$m2-dark-theme-foreground-palette, text);

@mixin focus-indicator($theme-or-config) {
  $primary: map.get($theme-or-config, primary);
  $accent: map.get($theme-or-config, accent);
  $is-dark: map.get($theme-or-config, is-dark);
  $link-color: map.get($theme-or-config, link-color);

  a.mat-focus-indicator,
  a.mat-mdc-focus-indicator,
  a.mat-focus-indicator,
  a.mat-mdc-focus-indicator {
    &:before {
      inset: -2px -4px;
    }
  }

  .mat-focus-indicator,
  .mat-mdc-focus-indicator,
  .mat-focus-indicator,
  .mat-mdc-focus-indicator {
    &:focus {
      outline: none;
    }

    &:before {
      inset: 0;
      position: absolute;
      box-sizing: border-box;
      pointer-events: none;
      border: $border-width $border-style $link-color;
    }

    @if ($is-dark == false) {
      &.mat-primary:before {
        border-color: mat.m2-get-color-from-palette($primary, 500);
      }

      &.mat-accent:before {
        border-color: mat.m2-get-color-from-palette($accent, 500);
      }
    } @else {
      &:before {
        border-color: $border-color-dark;
      }
    }
  }
}
