@use 'sass:map';
@use '@angular/material' as mat;

@mixin mat-snackbar($config) {
  $warn: map.get($config, warn);
  .mat-mdc-snack-bar-container {
    &.error {
      background: mat.m2-get-color-from-palette($warn, 300);
      color: mat.m2-get-color-from-palette($warn, '500-contrast');
      .mat-mdc-snack-bar-action {
        color: mat.m2-get-color-from-palette($warn, '500-contrast');
      }
    }
  }
}
