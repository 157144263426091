@mixin mat-dialog() {
  .mat-mdc-dialog-container {
    overflow: unset;
    display: flex;
    flex-direction: column;

    .mat-mdc-dialog-title {
      @extend .mb-0;
    }

    .mat-mdc-dialog-content {
      display: flex;
      flex-direction: column;
      min-height: 1px;
      .mat-mdc-tab-group {
        .mat-mdc-tab-header {
          @extend .px-0;
        }
      }
    }

    .mat-mdc-dialog-actions {
      @extend .pt-1;
      @extend .px-3;
      @extend .pb-2;
    }
  }

  .maximize {
    .mat-dialog-container > :first-child {
      @extend .h-100;
      @extend .d-flex;
      @extend .flex-column;
    }
    .mat-mdc-dialog-container .mat-mdc-dialog-content {
      max-height: unset;
    }
    .mat-dialog-content {
      max-height: inherit;
      @extend .h-100;
    }
  }
}
